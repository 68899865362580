'use client'
import React from 'react'
import { useState } from 'react';
import { ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import Link from 'next/link';

export default function Services() {
    const [dropdown, setDropdown] = useState(false);

    const toggleDropdown = () => {
        setDropdown(!dropdown);
    };

  return (
    <section id="services" className='bg-bg-brown'>
        <div className='pt-10 pl-8 pr-8 pb-10'>
            <h2 className='service-section-title'>Kenkien Pesu</h2>
            <h3 className='service-section-title mt-4 text-2xl'>Palvelut</h3>
            <div className='service-div'>
                <div className='service-box bg-bg-beige min-w-60'>
                    <h3 className='service-title'>CLEANIT PESU</h3>
                    <p className='text-bg-brown font-primary text-sm'>Likaisille kengille</p>
                    <ul className='service-list font-primary'>
                        <li>Huolellinen ulkopinnan pesu</li>
                        <li>Raikastava sisä pesu</li>
                        <li>Nauhojen pesu</li>
                        <li>Kellertävän pohjan valkaisu</li>
                        <li>Pinnan suojaus</li>
                    </ul>
                    <p className='price text-bg-brown font-primary text-sm'>alkaen 35</p>
                </div>
                <div className='service-box bg-bg-beige min-w-60'>
                    <h3 className='service-title'>CLEANIT PREMIUM</h3>
                    <p className='text-bg-brown font-primary text-sm'>Likaisille & kuluneille kengille</p>
                    <ul className='service-list font-primary'>
                        <li>Cleanit pesu</li>
                        <li>Kuluneen nahkan paikkaus</li>
                        <li>Kolhujen ja saumojen maalaus</li>
                        <li>Irronneen pohjan liimaus</li>
                    </ul>
                    <p className='price text-bg-brown font-primary text-sm'>alkaen 45</p>
                </div>
                <div className='service-box bg-bg-beige min-w-60'>
                    <h3 className='service-title'>MAALAUS & KUSTOMOINTI</h3>
                    <p className='text-bg-brown font-primary text-sm'>Puhtaille tai uusille kengille</p>
                    <p className='service-text font-primary'>Nahka, mokka tai kangas osien maalaus haluamillasi väreillä. </p>
                    <p className='price text-bg-brown font-primary text-sm'>alkaen 25</p>
                </div>
                
            </div>
            <div>
                <Link href="#contact" className='service-link'> 
                    <h3 className='pl-8'>Varaa palvelu</h3>
                    <ChevronRightIcon color={'#a48877'} boxSize={6} transition='0.3s ease' />
                </Link>  
            </div>
            <div className={`service-link ${dropdown ? 'service-link-open' : ''}`} onClick={toggleDropdown}>
                <h4 className='pl-8 service-link-text'>Miten hinta määräytyy?</h4>
                {dropdown ? <ChevronUpIcon color={'#a48877'} boxSize={6} transition='0.3s ease'/> : <ChevronDownIcon color={'#a48877'} boxSize={6} transition='0.3s ease' />}  
            </div>
            <div className={`service-dropdown ${dropdown ? 'service-dropdown-open' : ''}`}>
                <div>
                    <h4 className='service-dropdown-title'>Cleanit Pesu</h4>
                    <p className='sevice-p'>Cleanit pesun hinta määräytyy kenkäparin pinnassa olevien materiaalien perusteella.</p>
                    <p className='pt-5 sevice-p'>Vain 1 tai 2 materiaalia (nahka, mokka, kangas): 35€</p>
                    <p className='sevice-p'>3 tai useampaa materiaalia: 40€</p>
                    <p className='pt-3 sevice-p'>Jos kengissä on kellertävä pohja, joka valkaistaan tulee hintaan +10€</p>
                    <p className='pt-3 sevice-p'>Nauhojen ja lipan pesu kuuluu aina hintaan eikä sitä lasketa eri materiaaliksi.</p>
                </div>
                <div className="divider"></div>
                <div>
                    <h4 className='service-dropdown-title'>Cleanit Premium</h4>
                    <p className='sevice-p'>Cleanit pesun lisäksi hinta määräytyy tarvittavien entisöinti palveluiden pesuteella.</p>
                    <p className='pt-5 sevice-p'>Nahan täyttö ja maalaus + 10€</p>
                    <p className='sevice-p'>Pienten kolhujen ja saumojen maalaus + 10€</p>
                    <p className='sevice-p'>Paikoittain irronneen pohjan liimaus + 10€</p>
                </div>
                <div className="divider"></div>
                <div>
                    <h4 className='service-dropdown-title'>Maalaus & kustomointi</h4>
                    <p className='sevice-p'>Maalaukset tehdään aina puhtaalle ja hyväkuntoiselle pinnalle.</p>
                    <p className='pt-5 sevice-p'>Maalauksen hinta koostuu aloitus hinnasta 25€ ja jokaisesta maalattavasta lisä kohdasta +7,50€</p>
                    <p className='pt-3 sevice-p'>Eri kohdiksi lasketaan kengän alueet, jotka ovat eri materiaalia, väriä tai palaa, ja jotka ovat liitetty toisiinsa saumalla tai liimalla. Eri alueet ovat usein eri tasoissa kengän pinnalla.</p>
                    <p className='pt-3 sevice-p'>Jokainen kenkä on kuitenkin erillainen ja hinnoittelu antaa suuntaa palvelun kustannuksesta. Tarkka hinta sovitaan kun otat meihin yhteyttä.</p>
                </div>
            </div>
        </div>
    </section>
  )
}
