import React from 'react';
import { chakra, Flex, Box, Text, useCheckbox, CheckboxProps } from "@chakra-ui/react";
import { CheckCircleIcon } from '@chakra-ui/icons';

interface CheckboxServiceProps extends CheckboxProps {
    value: string;
}



const CheckboxService: React.FC<CheckboxServiceProps> = (props) => {
    const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } = useCheckbox(props);

    return(
      <chakra.label
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='space-between'
        maxW='md'
        border='2px solid'
        borderColor='#755F52'
        rounded='lg'
        px={3}
        py={2}
        cursor='pointer'
        width='100%'
        height='100px'
        background={state.isChecked ? '#a48877' : 'transparent'}
        transition='background-color 0.3s ease, border-color 0.3s ease'
        _hover={{
          border: '2px solid',
          background: '#a48877'
        }}
        {...htmlProps}
      >
      <input {...getInputProps()} hidden />
      <Text fontFamily={'PrimaryFont'} color='gray.700' fontSize={{ base: '8px', sm:'11px', md:'14px', lg: '16px' }} textAlign='center'  height='40px' display='flex' alignItems='center' justify-content='center' {...getLabelProps()}>
        {props.value}
      </Text>
      <Flex
        border='1px'
        borderColor='#a48877'
        borderRadius='5px'
        display='flex'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        background='#E4DED7'
        w={7}
        h={7}
        {...getCheckboxProps()}
      >
        {state.isChecked && <CheckCircleIcon color='#a48877' boxSize={5} transition='0.3s ease'/>}
      </Flex>
    </chakra.label>
  );
}

export default CheckboxService;
