'use client'
import React from 'react'
import { useState, ChangeEvent, FocusEvent } from 'react';
import { Container, FormControl, FormLabel, Flex, Image, Heading, Text, Box,  Input,Stack, Textarea, Button, useCheckboxGroup, useRadioGroup, FormErrorMessage, useToast } from "@chakra-ui/react";
import CheckboxService from './formComponents/CheckboxService';
import RadioMethod from './formComponents/RadioMethod';
import { DownloadIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { sendContactForm } from '@/lib/api';
import { z } from 'zod';

interface ContactFormValues {
    name: string;
    email: string;
    phone: string;
    services: string[];
    metod: string;
    payment: string;
    image: File | null;
    info: string;
}

interface ContactFormState {
    values: ContactFormValues;
    isLoading: boolean;
    error: string;
}

//Initial values for the form
const initValues: ContactFormValues = { 
    name: "",
    email: "",
    phone: "",
    services: [] as string[],
    metod: '',
    payment: '',
    image: null as File | null,
    info: '',
}

//Initialy none of the vlaues are touched
const initTouches = { 
    name: false,
    email: false,
}

//wraps initial values to maintain the structure
const initState: ContactFormState = {
    values: initValues,
    isLoading: false,
    error: '',
}

//Schema for validation
const validator = z.string().email();

export default function ContactForm() {
    const toast = useToast()
    const [state, setState] = useState(initState);
    const [touched, setTouched] = useState(initTouches)
    const {values, isLoading, error} = state;

    // State to track hover and upload status for image upload
    const [isHovered, setIsHovered] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [imgUrl, setImageUrl] = useState<string |  null>(null);

    // Handle hover state
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
        const { target } = event;
        setTouched((prev) => ({
            ...prev,
            [target.name]: true,
        }));
    };

    //handles change for input fields, updating the state
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        setState((prev) => ({
            ...prev,
            values: {
                ...prev.values,
                [target.name]: target.value,
            }
        }));

    }

    //hadling the checkbox values
    const handleCheckboxChange = (value: string[]) => {
        setState(prev => ({
            ...prev,
            values: {
                ...prev.values,
                services: value
            }
        }));
    };

    //handle radio change
    const handleMethodChange = (value: string) => {
        setState(prev => ({
            ...prev,
            values: {
                ...prev.values,
                metod: value
            }
        }));

    };

    //hadling payment radio
    const handlePaymentChange = (value: string) => {
        setState(prev => ({
            ...prev,
            values: {
                ...prev.values,
                payment: value
            }
        }));
     
    };

    //handling image uploads
    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setState(prev => ({
                ...prev,
                values: {
                    ...prev.values,
                    image: file
                }
            }));
            if (file) {
                setIsUploaded(true); // Mark as uploaded if a file is selected
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageUrl(reader.result as string);
                }
                reader.readAsDataURL(file);
            } else {
                setIsUploaded(false); // Reset if no file is selected
                setImageUrl(null);
            }
        }
    };

    //handles change for input fields, updating the state
    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { target } = event;
        setState((prev) => ({
            ...prev,
            values: {
                ...prev.values,
                [target.name]: target.value,
            }
        }));
    };

    //Handling the form submit
    const onSubmit = async () => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        
        try {

            await sendContactForm(values);
            setTouched(initTouches);
            setState(initState);
            setIsUploaded(false);
            setImageUrl(null);
            toast({
                title: "Lomake lähetetty.",
                description: "Olemme sinuun pian yhteydessä sähköpostitse",
                status: "success",
                duration: 5000,
                position: "bottom",
            })
        } catch (error: any) {
            setState((prev) => ({
                ...prev,
                isLoading: false,
                error: error.message,
            }));
        } finally {
            setState(prev => ({ ...prev, isLoading: false }));
        }
    };

    //manages the state of the checkbox group
    const { getCheckboxProps } = useCheckboxGroup({
        value: values.services, //current values
        onChange: handleCheckboxChange, //calls function when values changes
    });

    //manages the state of the checkbox group
    const { getRadioProps: getMethodRadioProps } = useRadioGroup({
        value: values.metod, //current values
        onChange: handleMethodChange, //calls function when values changes
    });

    const { getRadioProps: getPaymentRadioProps } = useRadioGroup({
        value: values.payment,
        onChange: handlePaymentChange,
    });



  return (
    <section className='formSection'>
        <div className='pt-24 pb-24 '>
            <Container id="contact" className='formContainer' height="100%" width='100%' maxW='800px' p={10} >
                <h3 className='form-heading'>KENGISTÄSI CLEANIT</h3 >
                {error && (
                    <Text color="red.300" my={4} fontSize="xl">
                        {error}
                    </Text>
                )}
                <FormControl isRequired isInvalid={touched.name && !values.name} color='#342F2D' pt='15px'>
                    <FormLabel fontFamily='subHeadingFont' mb='0'>Nimi</FormLabel>
                    <Input
                        type='text'
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={onBlur}
                        border='solid 1px #a48877'
                        _hover={{
                            border: '2px solid #755F52',
                        }}
                        _invalid={{
                            border: '2px solid #C84B31'
                        }}
                       
                    />
                    <FormErrorMessage color='#C84B31'>Pakollinen kenttä</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={(touched.email && !values.email) || (touched.email && !validator.safeParse(values.email).success)} color='#342F2D' pt='15px'>
                    <FormLabel fontFamily='subHeadingFont' mb='0'>Sähköposti</FormLabel>
                    <Input
                        type='email'
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={onBlur}
                        border='solid 1px #a48877'
                        _hover={{
                            border: '2px solid #755F52',
                        }}
                        _invalid={{
                            border: '2px solid #C84B31'
                        }}
                    />
                    <FormErrorMessage color='#C84B31'>Syötä kelvollinen sähköposti</FormErrorMessage>
                </FormControl>
                <FormControl color='#342F2D' pt='15px'>
                    <FormLabel fontFamily='subHeadingFont' mb='0' >Puhelin</FormLabel>
                    <Input
                        type='tel'
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        border='solid 1px #a48877'
                        _hover={{
                            border: '2px solid #755F52',
                        }}
                        _invalid={{
                            border: '2px solid #C84B31'
                        }}
                    />
                </FormControl>
                <FormControl mb={4} mt={4} color='#342F2D' isRequired>
                            <FormLabel fontFamily='subHeadingFont'>Valitse Palvelut</FormLabel>
                            <Flex direction='row' align='center' justify='space-between' width="100%" gap={{base: '4px', sm: '10px', md: '20px', lg: '30px'}}>
                                <CheckboxService value='Cleanit pesu' {...getCheckboxProps({ value: 'Cleanit pesu' })} flex="1" />
                                <CheckboxService value='Cleanit Premium' {...getCheckboxProps({ value: 'Cleanit Premium' })} flex="1" />
                                <CheckboxService value='Maalaus & kustomointi' {...getCheckboxProps({ value: 'Maalaus & kustomointi' })} flex="1"/>
                            </Flex>
                </FormControl>
                <FormControl mb={4} mt={4} color='#342F2D' isRequired>
                            <FormLabel fontFamily='subHeadingFont'>Valitse Toimintatapa</FormLabel>
                            <Flex direction='row' align='center' justify='space-between' gap={{base: '4px', sm: '10px', md: '20px', lg: '30px'}}>
                                <RadioMethod value='Tuonti' {...getMethodRadioProps({ value: 'Tuonti' })} />
                                <RadioMethod value='Posti' {...getMethodRadioProps({ value: 'Postitus' })} />
                            </Flex >
                </FormControl>
                <FormControl mb={4} mt={4} color='#342F2D' isRequired>
                            <FormLabel fontFamily='subHeadingFont'>Valitse Maksutapa</FormLabel>
                            <Flex direction='row' align='center' justify='space-between' gap={{base: '4px', sm: '10px', md: '20px', lg: '30px'}}>
                                <RadioMethod value='Kortti' {...getPaymentRadioProps({ value: 'Kortti'})}  />
                                <RadioMethod value='Käteinen' {...getPaymentRadioProps({ value: 'Käteinen' })} />
                                <RadioMethod value='Lasku' {...getPaymentRadioProps({ value: 'Lasku' })} />
                            </Flex>
                </FormControl>
                <div className='flex gap-6'>
                    <FormControl display='flex' flexDirection='column'>
                        <FormLabel fontFamily='subHeadingFont' fontSize={{ base: '13px', sm:'16px', md:'20px'}} width='100%' position='absolute' padding='5px' paddingTop='10px' color='#342F2D' textAlign='center' zIndex={1}>Lataa kuva</FormLabel>
                        <Input
                            type='file'
                            name='image'
                            accept='image/*'
                            onChange={handleImageChange}
                            height='100%'
                            width='100%'
                            flexGrow='1'
                            opacity='0'
                            position='absolute'
                            cursor='pointer'
                            zIndex={1}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        />
                          <Box
                            as='button'
                            height='100%'
                            flexGrow={1}
                            display='flex'
                            flexDirection='column'
                            gap='15px'
                            alignItems='center'
                            justifyContent='center'
                            background={isHovered || isUploaded ? '#dcd4cd' : 'transparent'}
                            paddingTop='20px'
                            border={isHovered || isUploaded ? '2px solid #755F52' : '2px dashed #755F52'}
                            rounded='lg'
                            cursor='pointer'
                            fontSize='lg'
                            color='#342F2D'
                            zIndex={0} 
                        >
                            {imgUrl ? (
                                <Flex direction='row' align='center' justify='space-between'>
                                    <Image src={imgUrl} alt='Uploaded Image' boxSize={{base: '9', sm: '14'}} />
                                    <Button background='transparent'><CheckCircleIcon color={'#755F52'} boxSize={{base: '6', sm: '8'}} /></Button>
                                </Flex>
                                ) : (
                                <Flex direction='column' align='center' justify='space-between' gap={2}>
                                    <Text fontSize={{ base: '10px', sm:'12px', md:'14px'}} fontFamily='subHeadingFont'>Click or Drag to Upload Image </Text>
                                    <DownloadIcon color={isHovered ? '#a48877' : '#755F52'} boxSize={6} transition='0.3s ease' />
                                </Flex>
                            )}

                        </Box>

                    </FormControl>
                    <FormControl>
                            <FormLabel fontFamily='subHeadingFont'>Lisätietoa</FormLabel>
                            <Textarea 
                                name='info'
                                placeholder='esim. haluan vaihtaa uudet nauhat '
                                fontSize={{ base: '10px', sm:'12px', md:'14px'}}
                                rows={4}
                                value={values.info}
                                onChange={handleTextChange}
                                color='#342F2D'
                                border='solid 1px #a48877'
                                _hover={{
                                    border: 'solid 1px #755F52',
                                }}
                            />
                    </FormControl>
                </div>
                
                <Button
                    loadingText='Lähettää'
                    isLoading={isLoading}
                    colorScheme='teal'
                    variant='outline'
                    isDisabled={!values.name || !values.email || !values.metod || !(values.services.length > 0) || !values.payment || !validator.safeParse(values.email).success}
                    onClick={onSubmit}
                    marginTop='15px'
                    justifyContent='center'
                    width='100%'
                >
                    Lähetä
                </Button>
            </Container>
        </div>

    </section>
  )
}
