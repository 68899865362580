export interface ContactFormData {
    name: string,
    email: string,
    phone: string,
    services: string[],
    metod: string,
    payment: string,
    image: File | null,
    info: string,
}

export const sendContactForm = async (data: ContactFormData): Promise<Response> =>{
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    data.phone && formData.append("phone", data.phone);
    if (data.services && data.services.length > 0) {
        const servicesString = data.services.join(", ");
        formData.append("services", servicesString);
    }
    formData.append("metod", data.metod);
    formData.append("payment", data.payment);
    data.image && formData.append("image", data.image);
    data.info && formData.append("info", data.info);

    try {
        const response = await fetch('/api/contact', {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get the error message
            throw new Error(`Lomakkeen lähettäminen epäonnistui: ${errorText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Failed to send contact form:', error);
        throw new Error(`Lomakkeen lähettäminen epäonnistui`); // Optionally re-throw or handle the error as needed
    }
}